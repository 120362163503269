import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 园区活动 - 分页查询
 * @param params
 */
export function getParkActivityList(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/activity/getActivityByParam',
    method: 'get',
    params,
  });
}

/**
 * 园区活动 - 新增
 * @param params
 */
export function addParkActivity(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/activity/addActivity',
    method: 'post',
    data: params,
  });
}

/**
 * 园区活动 - 分页查询
 * @param params
 */
export function getParkActivityDetail(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/activity/getDetails',
    method: 'get',
    params,
  });
}

/**
 * 园区活动 - 编辑
 * @param params
 */
export function editParkActivity(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/activity/editActivity',
    method: 'post',
    data: params,
  });
}

/**
 * 园区活动 - 删除
 * @param params
 */
export function deleteParkActivity(params) {
  return request({
    url: envInfo.bgApp.customerServicelegoPath + '/activity/deleteActivity',
    method: 'post',
    data: params,
  });
}
